<template>
    <div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group">
                                <label class="col-md-12 col-form-label font-weight-bold">Email</label>
                                <input type="email" class="form-control col-md-12 " id="floatingEmail" placeholder="usuario@dominio.com.br" v-model="form.user.email" required autofocus>
                                <span class="form-text text-danger">
                                    <small>{{errorText}}</small>
                                </span>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 col-form-label font-weight-bold">Senha</label>
                                <input type="password" class="form-control col-md-12" id="floatingPassword" placeholder="Senha" v-model="form.user.password" @keyup.enter="login()" required>
                            </div>
                            <div class="py-3 text-end">
                                <small><router-link to="forgotpassword" class="text-muted">Esqueceu a senha?</router-link></small>
                            </div>
                            <button class="btn btn-primary btn" v-on:click.prevent="login()" :disabled='isDisabledLogin'>Entrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import AuthBEAPI from '@/services/apibe/auth'
export default {
    data(){
        return {
            form: { 
                user: {
                    email: '',
                    password: '',
                },
            },
            errorText: ''
        }
    },
    methods: {
        login(){
            if (!(this.form.user.email.trim() != '' && this.form.user.password.trim() != '')){
                return false;
            }
            this.$isLoading(true) 
            AuthBEAPI.login(this.form).then(response => {
                localStorage.setItem('access_token', response.data.access_token)
                AuthBEAPI.me().then(response => {
                    let userLogged = JSON.stringify(response.data);
                    localStorage.setItem('userLogged', userLogged)
                    this.$store.state.userLogged = userLogged
                    if (this.$route.name != 'home'){
                        this.$isLoading(false) 
                        this.$router.push('home')
                    }
                }).catch(e => {
                    console.log(e)
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('userLogged')
                    this.errorText = "Ocorreu um erro ao carregar informações do usuário, tente mais tarde."
                    this.$isLoading(false) 
                })
            }).catch(e => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('userLogged')
                if(e.response.status == 401){
                    this.errorText = e.response.data.error
                }else{
                     this.errorText = "Ocorreu um erro desconhecido, tente mais tarde."
                }
                this.$isLoading(false) 
            })
        },
    },
    computed: {
        isDisabledLogin(){
            if (this.form.user.email.trim() != '' && this.form.user.password.trim() != ''){
                 return false;
            }
            return true;
        },
    },
    mounted: function () {
        this.$nextTick(function () {
            if (JSON.parse(localStorage.getItem('userLogged') != null)) {
                this.$isLoading(false) 
                this.$router.push('home')
            }else{
                this.$isLoading(false) 
            }
        }
    )},
}
</script>