import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '@/views/Login'
import Project from '@/views/Project'
import Person from '@/views/Person'
import User from '@/views/User'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'Home2',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/person',
    name: 'Person',
    component: Person
  },
  {
    path: '/project',
    name: 'Project',
    component: Project
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
]

const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
    const publicPages = [
        
        '/',
        '/login',
        '/forgotpassword',
        '/resetpassword',
        '/confirmmail',
        '/register',
    ]

    const authRequired = !publicPages.includes(to.path)
    const userLogged = localStorage.getItem('userLogged')
  
    if (authRequired && !userLogged) {
      return next('/login')
    }
  
    next();
  })

export default router