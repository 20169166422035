import { http } from './config';

export default{
    list:() => {
        return http.get('project')
    },
    show:(id) => {
        return http.get('project/'+id)
    },
    destroy:(id) => {
        return http.delete('project/'+id)
    },    
    update:(data) => {
        return http.put('project/'+data.project.idProject,data)
    },
    store:(data) => {
        return http.post('project',data)
    },
    listRoomModule:() => {
        return http.get('roommodule')
    },
    listRoom:() => {
        return http.get('room')
    },
}