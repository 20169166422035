<template>
  <div>
    <Menu/>
    <div class="container-fluid">
      <h3>Pessoas</h3>

        <div>
          <button v-if="!selectedPerson" type="button" class="btn btn-primary btn-sm mb-2" v-on:click.prevent="addPerson()"><i class="fas fa-plus"></i> Pessoa</button>
          <div class="table-responsive" v-if="personData != null && !selectedPerson">
            <input type="text" class="form-control form-control-sm mt-2 mb-2" v-model="search" maxlength="128" placeholder="Digite para buscar em todos os campos">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('type_person.name')" >Tipo <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('name')" >Nome <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('nickname')" >Apelido <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('mobilePhoneNumber')" >Celular <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('cpfcnpj')">CPF/CNPJ <i class="fas fa-sort"></i></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="person in personDataFilter" :key="person.idPerson" >
                  <td>{{person.type_person.name}}</td>
                  <td>{{person.name}}</td>
                  <td>{{person.nickname}}</td>
                  <td><the-mask readonly disabled class="form-control-plaintext p-0 m-0" v-model="person.mobilePhoneNumber" :mask="['(##) ####-####', '(##) #####-####']" /></td>
                  <td><the-mask readonly disabled class="form-control-plaintext p-0 m-0" v-model="person.cpfcnpj" :mask="['###.###.###-##', '##.###.###/####-##']" /></td>
                  <td><a href="#" v-on:click.prevent="showPerson(person.idPerson)"><i class="fas fa-search"></i></a></td>
                  <td><a href="#" v-on:click.prevent="deletePerson(person.idPerson)"><i class="fas fa-trash"></i></a></td>
                </tr>            
              </tbody>
            </table>
          </div>
        </div>          

        <div v-if="selectedPerson">
          <div v-if="errors" class="alert alert-danger" role="alert">
            <div v-for="(v, k) in errors" :key="k">
              <small>{{v.join(' ')}}</small>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="form-group col-md-6">
                <label>Tipo</label>
                <select v-model="selectedPerson.idTypePerson" class="form-control">
                  <option v-for="personType in personTypeData" :key="personType.idTypePerson" v-bind:value="personType.idTypePerson">
                    {{personType.name}}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Gênero</label>
                <select v-model="selectedPerson.idGender" class="form-control">
                  <option v-for="gender in genderData" :key="gender.idGender" v-bind:value="gender.idGender">
                    {{gender.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">      
              <div class="form-group col-md-6">
                <label>Nome</label>
                <input type="text" class="form-control" v-model="selectedPerson.name" required maxlength="256">
              </div>
              <div class="form-group col-md-6">
                <label>Apelido</label>
                <input type="text" class="form-control" v-model="selectedPerson.nickname" maxlength="64">
              </div>
            </div>            
            <div class="row">     
              <div class="form-group col-md-4">
                <label>CPF/CNPJ</label>
                <the-mask class="form-control" v-model="selectedPerson.cpfcnpj" :mask="['###.###.###-##', '##.###.###/####-##']" />
                <!-- <input type="text" class="form-control" v-model="selectedPerson.cpfcnpj" v-mask="['###.###.###-##', '##.###.###/####-##']"> -->
              </div>
              <div class="form-group col-md-4">
                <label>RG</label>
                <input type="text" class="form-control" v-model="selectedPerson.rg" maxlength="45">
              </div>
              <div class="form-group col-md-4">
                <label>Nascimento</label>
                <!-- <input type="date" class="form-control" v-model="selectedPerson.birthdate"> -->
                <input type="date" class="form-control" :value="selectedPerson.birthdate|moment" @input="selectedPerson.birthdate = $event.target.value">
              </div>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="email" class="form-control" v-model="selectedPerson.email" maxlength="256">
            </div>
            <div class="row">
              <div class="form-group col-md-4">
                <label>Celular</label>
                <the-mask class="form-control" v-model="selectedPerson.mobilePhoneNumber" required :mask="['(##) ####-####', '(##) #####-####']" />
                <!-- <input type="text" class="form-control" v-model="selectedPerson.mobilePhoneNumber" required :mask="['(##) ####-####', '(##) #####-####']"> -->
              </div> 
              <div class="form-group col-md-4">
                <label>Telefone residencial</label>
                <the-mask class="form-control" v-model="selectedPerson.residentialPhoneNumber" :mask="['(##) ####-####', '(##) #####-####']" />
                <!-- <input type="text" class="form-control" v-model="selectedPerson.residentialPhoneNumber" v-mask="['(##) ####-####', '(##) #####-####']"> -->
              </div> 
              <div class="form-group col-md-4">
                <label>Telefone comercial</label>
                <the-mask class="form-control" v-model="selectedPerson.commercialPhoneNumber" :mask="['(##) ####-####', '(##) #####-####']" />
                <!-- <input type="text" class="form-control" v-model="selectedPerson.commercialPhoneNumber" v-mask="['(##) ####-####', '(##) #####-####']"> -->
              </div> 
            </div>
          </div>
          <div class="card card-body">
            <h5>Endereços</h5>
            <div class="table-responsive" v-if="selectedPerson.person_addresses">
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">CEP</th>
                    <th scope="col">UF</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">Logradouro</th>
                    <th scope="col">Nº</th>
                    <th scope="col">Complemento</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(personAddress, index) in selectedPerson.person_addresses" :key="personAddress.address.idAddress" >
                    <td style="max-width: 120px">
                      <select v-model="personAddress.address.idTypeAddress" class="form-control form-control-sm">
                        <option v-for="typeAddress in personAddressTypeData" :key="typeAddress.idTypeAddress" v-bind:value="typeAddress.idTypeAddress">
                          {{typeAddress.name}}
                        </option>
                      </select>
                    </td>
                    <td style="max-width: 80px">
                      <the-mask class="form-control form-control-sm" v-model="personAddress.address.cep" :mask="['##.###-###']" @blur.native="searchCEP(index)" />
                      <!-- <input type="text" class="form-control form-control-sm" v-model="personAddress.address.cep" v-on:blur="searchCEP(index)"> -->
                    </td>
                    <td style="max-width: 40px"><input type="text" class="form-control form-control-sm" v-model="personAddress.address.uf" maxlength="2"></td>
                    <td style="max-width: 120px"><input type="text" class="form-control form-control-sm" v-model="personAddress.address.state" maxlength="128"></td>
                    <td><input type="text" class="form-control form-control-sm" v-model="personAddress.address.city" maxlength="128"></td>
                    <td><input type="text" class="form-control form-control-sm" v-model="personAddress.address.street" maxlength="256"></td>
                    <td style="max-width: 60px"><input type="text" class="form-control form-control-sm" v-model="personAddress.address.number" maxlength="16"></td>
                    <td><input type="text" class="form-control form-control-sm" v-model="personAddress.address.complement" maxlength="256"></td>
                    <td><a href="#" v-on:click.prevent="deleteAddress(index)"><i class="fas fa-trash"></i></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-group text-right">
              <button type="button" class="btn btn-primary btn-sm mt-0 text-right" v-on:click.prevent="addAddress()"><i class="fas fa-plus"></i> Endereço</button>
            </div> 
          </div>             

          <div class="form-group text-right mt-2">
            <button type="button" class="btn btn-secondary btn-sm" v-on:click.prevent="cancel()">Voltar</button>
            <button type="button" class="btn btn-primary btn-sm ml-2" v-on:click.prevent="save()">Salvar</button>
          </div> 
        </div>

    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import PersonBEAPI from '@/services/apibe/person'
import ViaCEPAPI from '@/services/viacep/viacep'
import moment from 'moment'

export default {
  data(){
    return {
      personData : null,
      genderData : null,
      personTypeData : null,
      personAddressTypeData : null,
      selectedPerson: null,
      errors: null,
      search: null,
      currentSort: null,
      currentSortDir: null

    }
  },
  components: {
    Menu
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY-MM-DD')
    }
  },
  computed: {
    personDataFilter(){
      let personDataComputed = this.personData
      if (this.search){
        personDataComputed = this.personData.filter(person => 
          (
            (person.type_person.name != null && person.type_person.name.toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            (person.name != null && person.name.toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            (person.nickname != null && person.nickname.toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            (person.mobilePhoneNumber != null && person.mobilePhoneNumber.toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            (person.cpfcnpj != null && person.cpfcnpj.toUpperCase().indexOf(this.search.toUpperCase()) >= 0)
          )
        )
      }

      if(this.currentSort){
        personDataComputed.sort((a,b) => {
          let modifier = 1

          a = eval('a.'+this.currentSort)
          b = eval('b.'+this.currentSort)

          if(this.currentSortDir === 'desc'){ 
            modifier = -1
          }

          if(a === b){
            return 0
          }

          if(!b || a < b){
            return -1 * modifier
          }

          if(!a || a > b){
            return 1 * modifier
          }
          
        })
      }

      return personDataComputed;
    }
  },
  methods: {
    showPerson(idPerson){
      this.$isLoading(true) 
      this.cancel()
      PersonBEAPI.show(idPerson).then(response => {
        this.selectedPerson = response.data;
        this.$isLoading(false)
      }).catch(e => {
        this.$isLoading(false)
        alert(e.response.data.error)
      })
    },
    sort(field){
      if(field === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }else{
        this.currentSortDir = 'asc'  
      }
      this.currentSort = field
    },
    cancel(){
      this.selectedPerson = null
      this.errors = null
      this.search = null
    },
    save(){
      if (this.selectedPerson.idPerson){
        this.update()
      }else{
        this.store()
      }
    },
    store(){
      this.$isLoading(true) 
      PersonBEAPI.store({ person : this.selectedPerson }).then(response => {
        this.selectedPerson = response.data
        this.errors = null
        PersonBEAPI.list().then(response => {
          this.personData = response.data
          this.selectedPerson = null
          this.$isLoading(false)
          this.$confirm({
            message: "Operação realizada com sucesso.",
            button: {
              no : "OK"
            }
          })
        }).catch(e => {
          console.log(e.response.data.error)
        })
      }).catch(e => {
        this.$isLoading(false)
        this.errors = Object.values(e.response.data.error)
      })
    },
    update(){
      this.$isLoading(true) 
      PersonBEAPI.update({ person : this.selectedPerson }).then(response => {
        this.selectedPerson = response.data
        this.errors = null
        PersonBEAPI.list().then(response => {
          this.personData = response.data
          this.$isLoading(false)
          this.$confirm({
            message: "Operação realizada com sucesso.",
            button: {
              no : "OK"
            }
          })
        }).catch(e => {
          console.log(e.response.data.error)
        })
      }).catch(e => {
        this.$isLoading(false)
        this.errors = Object.values(e.response.data.error)
      })
    },
    deletePerson(idPerson){
      this.$confirm(
        {
          message: 'Confirma a exclusão?',
          button: {
            no: 'Não',
            yes: 'Sim'
          },
          callback: confirm => {
            if (confirm) {
              this.$isLoading(true)
              // eslint-disable-next-line no-unused-vars
              PersonBEAPI.destroy(idPerson).then(response => {
                PersonBEAPI.list().then(response => {
                  this.personData = response.data
                  this.$isLoading(false)
                }).catch(e => {
                  this.$isLoading(false)
                  console.log(e.response.data.error)
                })
              }).catch(e => {
                this.$isLoading(false)
                alert(e.response.data.error)
              })
            }
          }
        }
      )      
    },
    deleteAddress(index){
      this.selectedPerson.person_addresses.splice(index, 1)
    },
    addAddress(){
      this.selectedPerson.person_addresses.push({ 
        address: {
          //idAddress: this.selectedPerson.person_addresses.length,
          uf: "",
          city: "",
          state: "",
          street: "",
          number: "",
          cep: "",
          complement: "",
          idTypeAddress: 1
        } 
      })
    },
    addPerson(){
      this.selectedPerson =
        {
          idPerson: null,
          name: null,
          nickname: null,
          cpfcnpj: null,
          rg: null,
          birthdate: null,
          residentialPhoneNumber: null,
          commercialPhoneNumber:null,
          mobilePhoneNumber: null,
          idTypePerson: 1,
          idGender: 1,
          person_addresses: []
        } 
    },
    searchCEP(index){
      this.$isLoading(true)
      let cep = this.selectedPerson.person_addresses[index].address.cep
      ViaCEPAPI.searchCEP(cep).then(response => {
        let cepData = response.data
        this.selectedPerson.person_addresses[index].address.uf = cepData.uf
        this.selectedPerson.person_addresses[index].address.state = cepData.uf
        this.selectedPerson.person_addresses[index].address.city = cepData.localidade
        this.selectedPerson.person_addresses[index].address.street = cepData.logradouro + ' - ' + cepData.bairro
        this.selectedPerson.person_addresses[index].address.complement = cepData.complemento
        this.$isLoading(false)
        // eslint-disable-next-line no-unused-vars
      }).catch(e => {
        this.$isLoading(false)
      })
    },
  },
  mounted: function(){
    this.$isLoading(true) 
    PersonBEAPI.list().then(response => {
        this.personData = response.data;

        PersonBEAPI.listPersonGender().then(response => {
          this.genderData = response.data;

          PersonBEAPI.listPersonType().then(response => {
            this.personTypeData = response.data;

            PersonBEAPI.listPersonAddressType().then(response => {
              this.personAddressTypeData = response.data;
              this.$isLoading(false) 

            }).catch(e => {
              this.$isLoading(false) 
              console.log(e.response.data.error)
            })

          }).catch(e => {
            this.$isLoading(false) 
            console.log(e.response.data.error)
          })

        }).catch(e => {
          this.$isLoading(false) 
          console.log(e.response.data.error)
        })

    }).catch(e => {
      this.$isLoading(false) 
      console.log(e.response.data.error)
    })
  }
}
</script>
