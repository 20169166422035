<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
        <router-link class="navbar-brand" to="Home">Móveis</router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <router-link class="nav-link" to="Person">Pessoas</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="Project">Projetos</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="User">Usuários</router-link>
                </li>
            </ul>

            <ul class="nav navbar-nav navbar-right" id="leftMenu">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{userLogged && userLogged.name ? userLogged.name : 'desconhecido'}}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="#" v-on:click.prevent="logout()">Sair</a>
                        <a class="dropdown-item" href="#">Minha conta</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import AuthBEAPI from '@/services/apibe/auth'
export default {
    data(){
        return {
            userLogged: null
        }
    },
    mounted: function () {
        this.userLogged = JSON.parse(localStorage.getItem('userLogged'))
    },
    methods: {
        logout() {
            AuthBEAPI.logout()
            this.$store.state.userLogged = null;
            this.$router.push('/')
        }
    }
}
</script>
