<template>
  <div>
    <Menu/>
    <div class="container-fluid">
      <h3>Projetos</h3>

        <div>
          <button v-if="!selectedProject" type="button" class="btn btn-primary btn-sm mb-2" v-on:click.prevent="addProject()"><i class="fas fa-plus"></i> Projeto</button>
          <div class="table-responsive" v-if="projectData != null && !selectedProject">
            <input type="text" class="form-control form-control-sm mt-2 mb-2" v-model="search" maxlength="128" placeholder="Digite para buscar em todos os campos">
            <table class="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('name')" >Nome <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('status')" >Status <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('start')" >Entrada <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('execution')" >Executivo <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('signature')" >Assinatura <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('material')" >Material <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('manufactureStart')" >Inc. Fabric. <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('manufactureEnd')" >Fim. Fabric. <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('assemblyStart')" >Inc. Monta. <i class="fas fa-sort"></i></th>
                  <th scope="col" style="cursor:pointer" v-on:click.prevent="sort('assemblyEnd')" >Fim. Monta. <i class="fas fa-sort"></i></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="project in projectDataFilter" :key="project.idProject" >
                  <td>{{project.name}}</td>
                  <td>{{project.status}}</td>
                  <td>{{project.start | moment}}</td>
                  <td>{{project.execution | moment}}</td>
                  <td>{{project.signature | moment}}</td>
                  <td>{{project.material | moment}}</td>
                  <td>{{project.manufactureStart | moment}}</td>
                  <td>{{project.manufactureEnd | moment}}</td>
                  <td>{{project.assemblyStart | moment}}</td>
                  <td>{{project.assemblyEnd | moment}}</td>                  

                  <td><a href="#" v-on:click.prevent="showProject(project.idProject)"><i class="fas fa-search"></i></a></td>
                  <td><a href="#" v-on:click.prevent="deleteProject(project.idProject)"><i class="fas fa-trash"></i></a></td>
                </tr>            
              </tbody>
            </table>
          </div>
        </div>



        <div v-if="selectedProject">
          <div v-if="errors" class="alert alert-danger" role="alert">
            <div v-for="(v, k) in errors" :key="k">
              <small>{{v.join(' ')}}</small>
            </div>
          </div>

          <div>
            <div class="row">      
              <div class="form-group col-md-6">
                <label>Nome</label>
                <input type="text" class="form-control" v-model="selectedProject.name" required maxlength="256">
              </div>
              <div class="form-group col-md-6">
                <label>Status</label>
                <!-- <input type="text" class="form-control" v-model="selectedProject.status" maxlength="64"> -->
                <select v-model="selectedProject.status" class="form-control">
                  <option v-for="status in statusData" :key="status" v-bind:value="status">
                    {{status}}
                  </option>
                </select>                        
              </div>
            </div>            
            <div class="row">     
              <div class="form-group col-md-3">
                <label>Entrada</label>
                <div class="input-group mb-3">
                  <input type="date" class="form-control" :value="selectedProject.start|momentInput" @input="selectedProject.start = $event.target.value">
                  <div class="input-group-append">
                    <button class="btn btn-outline-primary" type="button" v-on:click.prevent="calcDates()"><i class="fas fa-calculator"></i></button>
                  </div>
                </div>                  
              </div>              
              <div class="form-group col-md-3">
                <label>Executivo</label>
                <input type="date" class="form-control" :value="selectedProject.execution|momentInput" @input="selectedProject.execution = $event.target.value">
              </div>
              <div class="form-group col-md-3">
                <label>Assinatura</label>
                <input type="date" class="form-control" :value="selectedProject.signature|momentInput" @input="selectedProject.signature = $event.target.value">
              </div>
              <div class="form-group col-md-3">
                <label>Material</label>
                <input type="date" class="form-control" :value="selectedProject.material|momentInput" @input="selectedProject.material = $event.target.value">
              </div>
            </div>
            <div class="row">     
              <div class="form-group col-md-3">
                <label>Inc. Fabric.</label>
                <input type="date" class="form-control" :value="selectedProject.manufactureStart|momentInput" @input="selectedProject.manufactureStart = $event.target.value">
              </div>              
              <div class="form-group col-md-3">
                <label>Fim. Fabric.</label>
                <input type="date" class="form-control" :value="selectedProject.manufactureEnd|momentInput" @input="selectedProject.manufactureEnd = $event.target.value">
              </div>
              <div class="form-group col-md-3">
                <label>Inc. Monta.</label>
                <input type="date" class="form-control" :value="selectedProject.assemblyStart|momentInput" @input="selectedProject.assemblyStart = $event.target.value">
              </div>
              <div class="form-group col-md-3">
                <label>Fim. Monta.</label>
                <input type="date" class="form-control" :value="selectedProject.assemblyEnd|momentInput" @input="selectedProject.assemblyEnd = $event.target.value">
              </div>
            </div>
          </div>

          <div class="card card-body">
            <h5>Pessoas do Projeto</h5>
            <div class="table-responsive" >
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Apelido</th>
                    <th scope="col">CPF</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(projectPerson, index) in selectedProject.project_people" :key="index">
                    <td >{{projectPerson.person.name}}</td>
                    <td style="width:670px">{{projectPerson.person.nickname}}</td>
                    <td style="width:160px"><the-mask readonly disabled class="form-control-plaintext p-0 m-0" v-model="projectPerson.person.cpfcnpj" :mask="['###.###.###-##', '##.###.###/####-##']" /></td>
                    <td class="text-center" style="width: 40px"><a href="#" v-on:click.prevent="deleteProjectPerson(index)"><i class="fas fa-trash"></i></a></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h5>Pessoas do Disponíveis</h5>
            <div class="table-responsive" >
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Apelido</th>
                    <th scope="col">CPF</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="person in personData.filter(p => !selectedProject.project_people.map(pp => pp.idPerson).includes(p.idPerson))" :key="person.idPerson">
                    <td >{{person.name}}</td>
                    <td style="width:670px">{{person.nickname}}</td>
                    <td style="width:160px"><the-mask readonly disabled class="form-control-plaintext p-0 m-0" v-model="person.cpfcnpj" :mask="['###.###.###-##', '##.###.###/####-##']" /></td>
                    <td class="text-center" style="width: 40px"><a href="#" v-on:click.prevent="addProjectPerson(person)"><i class="fas fa-arrow-up"></i></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card card-body mt-2">
              <h5>Módulos do Projeto</h5>
              <div class="table-responsive" >
                <table class="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Ambiente</th>
                      <th scope="col">Módulos</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody id="accordion">
                    <tr v-for="(projectRoom, index_projectRoom) in selectedProject.project_rooms" :key="index_projectRoom">
                      
                      <td v-if="projectRoom.idProjectRoom" style="width:350px">{{projectRoom.room.name}}</td>

                      <td v-if="!projectRoom.idProjectRoom" style="width:350px">
                        <select v-model="projectRoom.idRoom" class="form-control">
                          <option v-for="room in roomData" :key="room.idRoom" v-bind:value="room.idRoom">
                            {{room.name}}
                          </option>
                        </select>                        
                      </td>
                      
                      <td > 
                        <div class="row">
                          <div class="col-auto pr-1 mb-2" style="max-width: 350px;">
                            <a class="btn btn-primary btn-sm" data-toggle="collapse" :href="'#multiCollapse' + index_projectRoom" role="button" aria-expanded="false" :aria-controls="'multiCollapse' + index_projectRoom">Novo Módulo</a>
                          </div>
                          <div class="col pl-0">
                            <div class="collapse" :id="'multiCollapse' + index_projectRoom" data-parent="#accordion">
                              <div class="input-group">
                                <select v-model="selectedRoomModule" class="form-control form-control-sm">
                                  <option v-for="roomModule in roomModuleData" :key="roomModule.idRoomModule" v-bind:value="roomModule">
                                    {{roomModule.name}}
                                  </option>
                                </select>
                                <div class="input-group-append">
                                  <a class="btn btn-outline-primary btn-sm" :href="'#multiCollapse' + index_projectRoom" v-on:click.prevent="addProjectRoomModule(index_projectRoom)" aria-expanded="false" data-toggle="collapse" :aria-controls="'multiCollapse' + index_projectRoom"><i class="fas fa-plus"></i></a>
                                </div>
                              </div>
                            </div>
                          </div>                                
                        </div>
                              
                        <ul class="list-group">
                          <li v-for="(projectRoomModule, index_projectRoomModule) in projectRoom.project_room_modules" :key="index_projectRoomModule" class="list-group-item">
                            <div class="row">
                              <div class="col-auto">
                                <a href="#" v-on:click.prevent="minusProjectRoomModule(index_projectRoom, index_projectRoomModule)"><i class="fas fa-minus"></i></a>
                                <span class="badge badge-primary badge-pill mr-3 ml-3">
                                  {{ projectRoomModule.quantity }}
                                </span>
                                <a href="#" v-on:click.prevent="plusProjectRoomModule(index_projectRoom, index_projectRoomModule)"><i class="fas fa-plus"></i></a>
                              </div>
                              <div class="col-auto text-right">
                                {{ projectRoomModule.room_module.name }}
                              </div>
                              <div class="col text-right">                            
                                <a href="#" v-on:click.prevent="deleteProjectRoomModule(index_projectRoom, index_projectRoomModule)"><i class="fas fa-trash"></i></a>
                              </div>                              
                            </div>
                          </li>
                        </ul>
                      </td>
                      <td class="text-center" style="width: 40px">
                        <a href="#" v-on:click.prevent="deleteProjectRoom(index_projectRoom)"><i class="fas fa-trash"></i></a>
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
                <div class="form-group text-right">
                  <button type="button" class="btn btn-primary btn-sm mt-0 text-right" v-on:click.prevent="addProjectRoom()"><i class="fas fa-plus"></i> Ambiente</button>
                </div> 
              </div>              
          </div>

          <div class="form-group text-right mt-2">
            <button type="button" class="btn btn-secondary btn-sm" v-on:click.prevent="cancel()">Voltar</button>
            <button type="button" class="btn btn-primary btn-sm ml-2" v-on:click.prevent="save()">Salvar</button>
          </div> 
        </div>        

    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import ProjectBEAPI from '@/services/apibe/project'
import PersonBEAPI from '@/services/apibe/person'
import moment from 'moment'

export default {
  data(){
    return {
      projectData: null,
      personData: null,
      romData: null,
      roomModuleData: null,
      selectedRomData: null,
      selectedRoomModule: null,
      selectedProject: null,
      errors: null,
      search: null,
      currentSort: null,
      currentSortDir: null,
      statusData: ["Pedido (contrato/entrada)","Executivo","Assinatura","Compra Material","Inicio Fabricação","Fim Fabricação","Inicio Montagem","Fim Montagem"]

    }
  },
  components: {
    Menu
  },
  filters: {
    moment: function (date) {
      return date != null ? moment(date).format('DD/MM/YYYY') : '-'
    },
    
    momentInput: function (date) {
      return moment(date).format('YYYY-MM-DD')
    }
  },  
  computed: {
    projectDataFilter(){
      let projectDataComputed = this.projectData

      if (this.search){
        projectDataComputed = this.projectData.filter(project => 
          (
            (project.name != null && project.name.toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            (project.status != null && project.status.toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            ( project.start != null && this.$options.filters.moment(project.start).toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            ( project.execution != null && this.$options.filters.moment(project.execution).toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            ( project.signature != null && this.$options.filters.moment(project.signature).toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            ( project.material != null && this.$options.filters.moment(project.material).toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            ( project.manufactureStart != null && this.$options.filters.moment(project.manufactureStart).toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            ( project.manufactureEnd != null && this.$options.filters.moment(project.manufactureEnd).toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            ( project.assemblyStart != null && this.$options.filters.moment(project.assemblyStart).toUpperCase().indexOf(this.search.toUpperCase()) >= 0) ||
            ( project.assemblyEnd != null && this.$options.filters.moment(project.assemblyEnd).toUpperCase().indexOf(this.search.toUpperCase()) >= 0)
          )
        )
      }

      if(this.currentSort){
        projectDataComputed.sort((a,b) => {
          let modifier = 1

          a = eval('a.'+this.currentSort)
          b = eval('b.'+this.currentSort)

          if(this.currentSortDir === 'desc'){ 
            modifier = -1
          }

          if(a === b){
            return 0
          }

          if(!b || a < b){
            return -1 * modifier
          }

          if(!a || a > b){
            return 1 * modifier
          }
          
        })
      }

      return projectDataComputed
    }
  },  
  methods: {
    sort(field){
      if(field === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }else{
        this.currentSortDir = 'asc'  
      }
      this.currentSort = field
    },
    showProject(idPerson){
      this.$isLoading(true) 
      this.cancel()
      ProjectBEAPI.show(idPerson).then(response => {
        this.selectedProject = response.data
        this.$isLoading(false)
      }).catch(e => {
        this.$isLoading(false)
        alert(e.response.data.error)
      })
    },
    cancel(){
      this.selectedProject = null
      this.errors = null
      this.search = null
    },
    addProjectPerson(person){
      this.selectedProject.project_people.push({ 
          idProjectPerson: null,
          idProject: this.selectedProject.idProject,
          idPerson: person.idPerson,
          person: person
      })
    },
    addProjectRoom(){
      this.selectedProject.project_rooms.push({ 
        idProjectRoom: null,
        idProject: this.selectedProject.idProject,
        idRoom: this.roomData[0].idRoom,
        room: this.roomData[0],
        project_room_modules: []
      })
    },
    addProjectRoomModule(index_projectRoom){
      if(this.selectedRoomModule){
        
        if (this.selectedProject.project_rooms[index_projectRoom].project_room_modules.filter(prm => prm.idRoomModule == this.selectedRoomModule.idRoomModule).length > 0){
          this.$confirm({
            message: "Módulo já adicionado a este ambiente.",
            button: {
              no : "OK"
            }
          })
          return
        }
        
        
        this.selectedProject.project_rooms[index_projectRoom].project_room_modules.push({
          idRoomModule: this.selectedRoomModule.idRoomModule,
          quantity: 1,
          room_module: this.selectedRoomModule
        })
      }
    },
    plusProjectRoomModule(index_projectRoom, index_projectRoomModule){
      this.selectedProject.project_rooms[index_projectRoom].project_room_modules[index_projectRoomModule].quantity++
    },
    minusProjectRoomModule(index_projectRoom, index_projectRoomModule){
      if (this.selectedProject.project_rooms[index_projectRoom].project_room_modules[index_projectRoomModule].quantity > 1)
        this.selectedProject.project_rooms[index_projectRoom].project_room_modules[index_projectRoomModule].quantity--
    },    
    deleteProjectRoom(index){
      this.selectedProject.project_rooms.splice(index, 1)
    },
    deleteProjectRoomModule(index_projectRoom, index_projectRoomModule){
      this.selectedProject.project_rooms[index_projectRoom].project_room_modules.splice(index_projectRoomModule,1)
      if(this.selectedProject.project_rooms[index_projectRoom].project_room_modules.length == 0) {
        this.deleteProjectRoom(index_projectRoom)
      }
    },
    deleteProjectPerson(index){
      this.selectedProject.project_people.splice(index, 1)
    },    
    deleteProject(idProject){
      this.$confirm(
        {
          message: 'Confirma a exclusão?',
          button: {
            no: 'Não',
            yes: 'Sim'
          },
          callback: confirm => {
            if (confirm) {
              this.$isLoading(true)
              // eslint-disable-next-line no-unused-vars
              ProjectBEAPI.destroy(idProject).then(response => {
                ProjectBEAPI.list().then(response => {
                  this.projectData = response.data
                  this.$isLoading(false)
                }).catch(e => {
                  this.$isLoading(false)
                  console.log(e.response.data.error)
                })
              }).catch(e => {
                this.$isLoading(false)
                alert(e.response.data.error)
              })
            }
          }
        }
      )      
    },
    save(){
      let dontSave = false
      let msg = null

      if(this.selectedProject.project_people.length == 0){
        dontSave = true
        msg = "É preciso incluir ao menos uma pessoa no projeto."
      }

      if(!dontSave && this.selectedProject.project_rooms.length == 0){
        dontSave = true
        msg = "É preciso incluir ao menos um ambiente no projeto."
      }
      
      if(!dontSave){
        this.selectedProject.project_rooms.forEach(function(project_room){
          if (project_room.project_room_modules.length == 0){
            dontSave = true
            msg = "É preciso incluir ao menos um módulo para cada ambiente."
          }
        })
      }

      if(dontSave) {
          this.$confirm({
            message: msg,
            button: {
              no : "OK"
            }
          })
        return
      }

      if (this.selectedProject.idProject){
        this.update()
      }else{
        this.store()
      }
    },
    addProject(){
      this.selectedProject =
        {
          idProject: null,
          name: null,
          status: null,
          start: null,
          execution: null,
          signature: null,
          material: null,
          manufactureStart: null,
          manufactureEnd: null,
          assemblyStart: null,
          assemblyEnd: null,
          project_people: [],
          project_rooms: []

        } 
    },       
    update(){
      this.$isLoading(true) 
      ProjectBEAPI.update({ project : this.selectedProject }).then(response => {
        this.selectedProject = response.data
        this.errors = null
        ProjectBEAPI.list().then(response => {
          this.projectData = response.data
          this.$isLoading(false)
          this.$confirm({
            message: "Operação realizada com sucesso.",
            button: {
              no : "OK"
            }
          })
        }).catch(e => {
          console.log(e.response.data.error)
        })
      }).catch(e => {
        this.$isLoading(false)
        this.errors = Object.values(e.response.data.error)
      })
    },
    store(){
      this.$isLoading(true) 
      ProjectBEAPI.store({ project : this.selectedProject }).then(response => {
        this.projectData = response.data
        this.errors = null
        ProjectBEAPI.list().then(response => {
          this.projectData = response.data
          this.selectedProject = null
          this.$isLoading(false)
          this.$confirm({
            message: "Operação realizada com sucesso.",
            button: {
              no : "OK"
            }
          })
        }).catch(e => {
          console.log(e.response.data.error)
        })
      }).catch(e => {
        this.$isLoading(false)
        this.errors = Object.values(e.response.data.error)
      })
    },    
    calcDates(){
      let startDate = this.selectedProject.start ? this.$options.filters.moment(this.selectedProject.start) : this.$options.filters.moment(moment())
      this.selectedProject.start = this.$options.filters.momentInput(moment(startDate, "DD/MM/YYYY"))
      this.selectedProject.execution = this.$options.filters.momentInput(moment(startDate, "DD/MM/YYYY").add(7, 'days'))
      this.selectedProject.signature = this.$options.filters.momentInput(moment(startDate, "DD/MM/YYYY").add(10, 'days'))
      this.selectedProject.material = this.$options.filters.momentInput(moment(startDate, "DD/MM/YYYY").add(17, 'days'))
      this.selectedProject.manufactureStart = this.$options.filters.momentInput(moment(startDate, "DD/MM/YYYY").add(24, 'days'))
      this.selectedProject.manufactureEnd = this.$options.filters.momentInput(moment(startDate, "DD/MM/YYYY").add(31, 'days'))
      this.selectedProject.assemblyStart = this.$options.filters.momentInput(moment(startDate, "DD/MM/YYYY").add(38, 'days'))
      //this.selectedProject.assemblyEnd = this.$options.filters.momentInput(moment(startDate, "DD/MM/YYYY").add(7, 'days'))
    },
  },
  mounted: function(){
    this.$isLoading(true) 
    ProjectBEAPI.list().then(response => {
        this.projectData = response.data
        
        PersonBEAPI.list().then(response => {
          this.personData = response.data

          ProjectBEAPI.listRoomModule().then(response => {
            this.roomModuleData = response.data
            this.selectedRoomModule = this.roomModuleData[0]
            
            ProjectBEAPI.listRoom().then(response => {
              this.roomData = response.data
              this.selectedRoom = this.roomData[0]
              this.$isLoading(false) 
            }).catch(e => {
              console.log(e.response.data.error)
            })

          }).catch(e => {
            console.log(e.response.data.error)
          })

        }).catch(e => {
          console.log(e.response.data.error)
        })

    }).catch(e => {
      this.$isLoading(false)
      console.log(e.response.data.error)
    })
  }

}
</script>
