<template>
  <div>
    <Menu/>
    <div class="container-fluid">
      <h3>Usuários</h3>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'

export default {
  components: {
    Menu
  }
}
</script>
