import axios from 'axios'
//import router from '@/routes'
import router from '../../router'

const http = axios.create({
    //baseURL: 'http://127.0.0.1:8000/api/'
    //baseURL: 'http://apimoveis.priscillabagano.com.br/api/'
    baseURL: process.env.VUE_APP_BE_API_URL
})

http.interceptors.request.use(function (config) {
    let token = localStorage.getItem('access_token')
    if(token){
        config.headers.Authorization = 'Bearer ' + token
    }
    return config
})

http.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status == 401) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('userLogged')
        if(router.currentRoute.name != null && router.currentRoute.name != 'Login' && router.currentRoute.name != 'Register' && router.currentRoute.name != 'Resetpassword' && router.currentRoute.name != 'Confirmmail'){
            //location.reload()
            router.push('/login')
        }else{
            return Promise.reject(error)
        }
    }else{
        return Promise.reject(error)
    }
})

//export const http
export {http}