import { http } from './config';

export default{
    list:() => {
        return http.get('person')
    },
    show:(id) => {
        return http.get('person/'+id)
    },
    destroy:(id) => {
        return http.delete('person/'+id)
    },    
    update:(data) => {
        return http.put('person/'+data.person.idPerson,data)
    },
    store:(data) => {
        return http.post('person',data)
    },
    listPersonGender:() => {
        return http.get('gender')
    },
    listPersonType:() => {
        return http.get('typeperson')
    },
    listPersonAddressType:() => {
        return http.get('typeaddress')
    },
}