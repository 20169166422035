<template>
  <div id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log("api url: "+ process.env.VUE_APP_BE_API_URL)
  },
}
</script>